<template>
  <div class="m-0 p-3">
    <back-button class="ml-1"/>
    <div class="ml-2 d-flex justify-content-between">
      <h1>{{ 'Add New Event' }}</h1>
    </div>
    <event-form ref="form" :event="response.event" @store="storeEvent" form-type="store" :loader="loader"/>
  </div>
</template>

<script>
import Form from "@/views/Components/SuperAdmin/Event/Form";
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin.js';

export default {
  name: "StoreEvent",
  mixins: [generateErrorMessageMixin],
  components: {
    BackButton,
    EventForm: Form
  },
  data() {
    return {
      loader: false,
      response: {
        event: {...this.$store.getters['EventModule/getEvent']}
      }
    }
  },
  mounted() {
    if (this.$route.params.copyEventFlag)
      this.getEvent()
  },
  methods: {
    /**
     * Get Requested Event
     */
    getEvent() {
      let vm = this
      vm.loader = true
      const payload = {
        id: this.$route.params.id,
      }
      vm.$store.dispatch('EventModule/_getRequestedEvent', payload)
        .then(response => {
          vm.response.event = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Event',
            message: message
          })
          vm.$router.back()
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Store Event
     * @param formData
     */
    storeEvent(formData) {
      let vm = this
      this.loader = true

      delete formData.id
      vm.$store.dispatch('EventModule/_storeEvent', {formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Event Created Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          const message = error.response && error.response.data && error.response.data.errors ? vm.generateErrorMessage(error.response.data.errors)
            : (error.response.data.message ?? 'Something went wrong please try again in few minutes.')
          vm.$notify.error({
            title: 'Event',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>

</style>
